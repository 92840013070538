import { render, staticRenderFns } from "./dafBakara.vue?vue&type=template&id=064a3623&scoped=true&"
import script from "./dafBakara.vue?vue&type=script&lang=js&"
export * from "./dafBakara.vue?vue&type=script&lang=js&"
import style0 from "./dafBakara.vue?vue&type=style&index=0&id=064a3623&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064a3623",
  null
  
)

export default component.exports