import { render, staticRenderFns } from "./succesView.vue?vue&type=template&id=47c08b20&scoped=true&"
import script from "./succesView.vue?vue&type=script&lang=js&"
export * from "./succesView.vue?vue&type=script&lang=js&"
import style0 from "./succesView.vue?vue&type=style&index=0&id=47c08b20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c08b20",
  null
  
)

export default component.exports